.stack {
  display: flex;
}

.center {
  align-items: center;
}

.grow {
  flex-grow: 1;
}

.row {
  flex-direction: row;
  // Don't increase horizontal gaps if the base font size changes
  gap: 8px;
}

.col {
  flex-direction: column;
  gap: 0.5rem;
}

.p1 {
  padding: 0.5rem 8px;
}

.p2 {
  padding: 1rem 16px;
}

.p3 {
  padding: 1.5rem 24px;
}

.p4 {
  padding: 2rem 32px;
}

.p8 {
  padding: 4rem 64px;
}

.p0x {
  padding-left: 0;
  padding-right: 0;
}

.p1x {
  padding-left: 8px;
  padding-right: 8px;
}

.p2x {
  padding-left: 16px;
  padding-right: 16px;
}

.p3x {
  padding-left: 24px;
  padding-right: 24px;
}

.p4x {
  padding-left: 32px;
  padding-right: 32px;
}

.p8x {
  padding-left: 64px;
  padding-right: 64px;
}

.spacer,
.spacer.p1,
.spacer.p2,
.spacer.p3,
.spacer.p4 {
  padding-bottom: 0;
  padding-right: 0;
}

.fill {
  flex-grow: 1;
  height: 100%;
  width: 100%;
}
